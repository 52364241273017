import StripesSVG from "../images/stripes.svg"

export type StripesPosition = "topLeft" | "topRight" | "bottomRight" | "bottomLeft"

const Stripes = ({
  isFixed = false,
  position = "bottomRight",
  customPosition
}: {
  isFixed?: boolean
  position?: StripesPosition
  customPosition?: string
}) => {

  let coords = ""

  switch (position) {
    case "topLeft":
      coords = isFixed ? "top-10 left-10" : "top-4 left-4"
      break
    case "topRight":
      coords = isFixed ? "top-10 right-10" : "top-4 right-4"
      break
    case "bottomRight":
      coords = isFixed ? "bottom-10 right-10" : "bottom-4 right-4"
      break
    case "bottomLeft":
      coords = isFixed ? "bottom-10 left-10" : "bottom-4 left-4"
      break
  }

  const useCoords = customPosition || coords

  return (
    <img src={StripesSVG} className={`${isFixed ? "fixed" : "absolute"} ${useCoords} opacity-5 rounded pointer-events-none`} alt="" />
  )
}

export default Stripes