export const cdnImages = "http://cdn.jazzzo.com/abandoned-places/images/"
export const cdnPhotographers = "http://cdn.jazzzo.com/abandoned-places/photographers/"

export const images: Image[] = [
  {
    id: "750639135",
    photographerHandle: "jazzzo__",
  }, {
    id: "926982412",
    photographerHandle: "jazzzo__",
  }, {
    id: "2664600335",
    photographerHandle: "jazzzo__",
  }, {
    id: "3333328008",
    photographerHandle: "jazzzo__",
  }, {
    id: "3667732119",
    photographerHandle: "jazzzo__",
  }, {
    id: "3753655734",
    photographerHandle: "jazzzo__",
  }, {
    id: "4251541961",
    photographerHandle: "jazzzo__",
  }, {
    id: "rae_car",
    photographerHandle: "AngelRaeAI",
  }, {
    id: "rae_pyramid",
    photographerHandle: "AngelRaeAI",
  }, {
    id: "rae_skyscraper",
    photographerHandle: "AngelRaeAI",
  }, {
    id: "rae_house",
    photographerHandle: "AngelRaeAI",
  },
  
]

export const photographers: Photographer[] = [
  {
    name: "Jazzzo",
    handle: "jazzzo__",
  }, {
    name: "AngelRae",
    handle: "AngelRaeAI",
  }, {
    name: "cathodeDreams",
    handle: "cathode_dreams",
  }, {
    name: "Artificial Assets",
    handle: "ArtAssetsAI",
  },
]

export const unknownPhotographer: Photographer = {
  name: "Unknown",
  handle: "Unknown",
}

export const allPhotographers: Photographer = {
  name: "All",
  handle: "all",
}

export interface Image {
  id: string,
  photographerHandle: string,
  alt?: string
}

export interface Photographer {
  name: string,
  handle: string,
}