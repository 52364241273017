import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Gallery from './pages/Gallery';
import Home from './pages/Home';
import Photographers from './pages/Photographers';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/photographers" element={<Photographers />} />
      <Route path="/imprint" element={<Photographers />} />
    </Routes>
  );
}

export default App;
