import { ReactNode, useEffect } from "react"
import { Tooltip } from "react-tooltip"
import Header from "./Header"
import Stripes from "./Stripes"

const Page = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    const header = document.getElementsByTagName("header")[0]
    const content = document.getElementById("content")
    if (header && content) {
      const y = header.clientHeight
      content.style.paddingTop = y + 48 + 'px'
    }
  }, [])
  return (
    <>
      <Stripes isFixed />
      <Header />
      <div id="content" className={`mx-auto max-w-screen-lg w-full pb-20 px-2`}>
        {children}
      </div>
      <Tooltip id="tooltip" />
    </>
  )
}

export default Page