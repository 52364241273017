import Page from "../components/Page"
import { Photographer, photographers } from "../data/data"
import { getPhotographerImage, getPhotographerTwitterUrl } from "../data/helper"
import Placeholder from "../images/placeholder.jpg"

const Photographers = () => {
  return (
    <Page>
      <div className="@container/photographers">
        <div className="columns-2 @3xl/photographers:columns-5 gap-2 space-y-2">
          {photographers.map(photographer => <PhotographerTile photographer={photographer} />)}
        </div>
      </div>
    </Page>
  )
}

export default Photographers

const PhotographerTile = ({ photographer }: { photographer: Photographer }) => {
  return (
    <a
      href={getPhotographerTwitterUrl(photographer)}
      target="_blank"
      rel="noreferrer"
    >
      <div className="rounded-lg bg-surface overflow-hidden">
        <img
          src={getPhotographerImage(photographer)}
          alt={`${photographer.name}`}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = Placeholder;
          }} />
        <h3 className="p-2 font-semibold">{photographer.name}</h3>
      </div>
    </a>
  )
}