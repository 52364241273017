import { Transition } from "@headlessui/react";
import { ReactNode, useEffect } from "react"
import { X } from "react-feather";

const Overlay = ({
  children,
  isOpen,
  setIsOpen,
  classNameOverlay = "",
  trigger,
  full = false
}: {
  children: ReactNode
  isOpen: boolean
  setIsOpen: any
  classNameOverlay?: string
  trigger?: ReactNode
  full?: boolean
}) => {
  useEffect(() => {
    if (isOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "visible";
  }, [isOpen])

  useEffect(() => {
    document.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        setIsOpen(false)
      }
    })
  })

  return (
    <>
      {trigger && <div className="" onClick={() => setIsOpen(true)}>{trigger}</div>}
      <Transition
        show={isOpen}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-40"
      >
        <div
          className="absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-surface bg-opacity-95 z-[1] cursor-pointer"
          onClick={() => setIsOpen(false)}
        />
        <div
          className="absolute top-0 right-0 p-4 bg-dark z-[3] cursor-pointer group"
          onClick={() => setIsOpen(false)}
        >
          <X className="text-white w-4 h-4 transition-transform duration-300 group-hover:transform group-hover:rotate-180" />
        </div>
        <div className={`z-[2] cursor-default relative ${full ? "w-full h-full" : "flex items-center justify-center max-w-xl w-full bg-surface rounded p-4"} ${classNameOverlay}`}>
          {children}
        </div>
      </Transition>
    </>
  )
}

export default Overlay