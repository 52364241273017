import Page from "../components/Page"
import Stripes from "../components/Stripes"

const Home = () => {
  return (
    <Page>
      <div className="inline-block bg-surface rounded p-4 prose prose-invert relative">
        <p className="">Welcome to <span className=" text-primary font-semibold">Abandoned Places</span>, a captivating collection of photographs that take you on a journey through the forgotten corners of our world.</p>
        <p>This site showcases a striking array of images that capture the haunting beauty of abandoned buildings, structures, and landscapes. From crumbling factories, decaying hospitals, and forgotten amusement parks, to the remnants of once-thriving cities, these photographs offer a unique perspective on the passage of time and the ephemeral nature of human existence.</p>
        <p>Prepare to be transported to a world of forgotten memories and hidden secrets, as we explore the captivating and hauntingly alluring world of abandoned places.</p>
        <Stripes customPosition=" -bottom-4 -right-4" />
      </div>
    </Page>
  )
}

export default Home