import { ChevronLeft, ChevronRight } from "react-feather";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/keyboard";
import { Keyboard, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { cdnImages, Image } from "../data/data";

const Slider = ({
  images,
  initialSlide = 1,
}: {
  images: Image[]
  initialSlide?: number
}) => {
  return (
    <>
      <Swiper
        className="h-full w-full"
        // @ts-ignore
        modules={[Pagination, Navigation, Keyboard]}
        navigation={{
          prevEl: ".prev",
          nextEl: ".next",
        }}
        initialSlide={initialSlide}
        keyboard
        pagination={{
          type: "fraction",
          el: ".pagination",
          renderFraction(currentClass: any, totalClass: any) {
            return `<span class="${currentClass}" ></span><span class="mx-2">|</span><span class="${totalClass}" ></span>`;
          },
        }}
      >
        {images.map((image, i) => {
          return (
            <SwiperSlide key={i} className="!flex items-center justify-center text-center">
              <img alt={image.alt || ""} src={`${cdnImages}${image.id}`} className="max-w-full max-h-full w-auto h-auto" />
            </SwiperSlide>
          )
        }
        )}
      </Swiper>

      <NavigationButton direction="prev" />
      <NavigationButton direction="next" />

      <div
        className="
          pagination bg-surface rounded-sm py-2 px-4 text-sm text-white
          inline-flex items-center justify-center fixed bottom-2 right-2 z-10
        "
      >
        <div className="pagination-divider">|</div>
      </div>


    </>
  )
}

const NavigationButton = ({ direction }: { direction: "prev" | "next" }) => {
  return (
    <div
      className={`
        absolute text-dark cursor-pointer top-1/2 transform -translate-y-1/2 z-20 rounded-sm bg-white bg-opacity-50 p-4 transition-all duration-300
        ${direction === "prev" ? "left-2" : "right-2"}
        ${direction === "prev" ? "sm:hover:pl-8" : "sm:hover:pr-8"}
        ${direction}
    `}
    >
      {direction === "prev" ? (
        <ChevronLeft className="w-4 h-4 text-surface" />
      ) : (
        <ChevronRight className="w-4 h-4 text-surface" />
      )}
    </div>
  );
};


export default Slider