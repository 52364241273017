import { useEffect, useState } from "react"
import { Maximize2 } from "react-feather"
import { useSearchParams } from "react-router-dom"
import Overlay from "../components/Overlay"
import Page from "../components/Page"
import Slider from "../components/Swiper"
import { images, Image, cdnImages, photographers, Photographer, allPhotographers } from "../data/data"
import { getPhotographer, getPhotographerImage, getPhotographerTwitterUrl } from "../data/helper"
import Placeholder from "../images/placeholder.jpg"

const Gallery = () => {
  const [galleryIsOpen, setGalleryIsOpen] = useState(false)
  const [initialSlide, setInitialSlide] = useState(0)

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPhotographer, setCurrentPhotographer] = useState(searchParams.get("photographer"))
  const [filteredImages, setFilteredImages] = useState<Image[]>()

  useEffect(() => {
    if (currentPhotographer === "all" || currentPhotographer == null) setFilteredImages(images)
    else setFilteredImages(images.filter(image => image.photographerHandle === currentPhotographer))

    if (currentPhotographer) {
      searchParams.set("photographer", currentPhotographer);
      setSearchParams(searchParams);
    }
  }, [currentPhotographer, searchParams, setSearchParams])

  return (
    <>
      <Page>
        <div className="flex items-center space-x-1 mb-4">
          <PhotographerImage
            photographer={allPhotographers}
            currentPhotographer={currentPhotographer}
            setCurrentPhotographer={setCurrentPhotographer}
          />
          {photographers.map(photographer => (
            <PhotographerImage
              photographer={photographer}
              currentPhotographer={currentPhotographer}
              setCurrentPhotographer={setCurrentPhotographer}
              key={photographer.handle}
            />
          ))}
        </div>
        <div className="@container/gallery">
          <div className="columns-2 @xl/gallery:columns-3 gap-1 space-y-1">
            {filteredImages && filteredImages.length > 0 ? filteredImages?.map((image, i) => {
              return (
                <GalleryImage
                  image={image}
                  key={image.id}
                  onClick={() => { setGalleryIsOpen(true); setInitialSlide(i) }}
                />
              )
            })
              :
              <p className="prose prose-invert bg-surface rounded p-4 text-sm">Nothing to see here</p>
            }
          </div>
        </div>
      </Page>
      {filteredImages && <Overlay full isOpen={galleryIsOpen} setIsOpen={setGalleryIsOpen}>
        <Slider images={filteredImages} initialSlide={initialSlide} />
      </Overlay>
      }
    </>
  )
}

export default Gallery

const PhotographerImage = ({
  photographer,
  currentPhotographer,
  setCurrentPhotographer
}: {
  photographer: Photographer
  currentPhotographer: string | null
  setCurrentPhotographer: (handle: string) => void
}) => {
  return (
    <img
      src={getPhotographerImage(photographer)}
      className={`rounded-full w-10 h-10 p-0.5 border-2 ${photographer.handle === currentPhotographer ? "border-primary opacity-100" : "border-transparent opacity-50 hover:opacity-100"} transition-all duration-300 cursor-pointer`}
      onClick={() => { setCurrentPhotographer(photographer.handle) }}
      data-tooltip-id="tooltip"
      data-tooltip-content={photographer.name}
      alt={`${photographer.name}`}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = Placeholder;
      }}
    />
  )
}

const GalleryImage = ({
  image,
  onClick,
}: {
  image: Image
  onClick: () => void
}) => {
  const photographer = getPhotographer(image.photographerHandle)
  return (
    <div className="group relative overflow-hidden rounded-sm">
      <img
        src={`${cdnImages}${image.id}.jpg`}
        className="cursor-pointer"
        alt={`by ${photographer.name}`}
      />
      <div
        className="absolute top-0 left-0 right-0 bottom-0 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gradient-to-b from-black via-transparent to-black"
        onClick={onClick}
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 drop-shadow-md"><Maximize2 size={24} className="" /></div>
        <a
          href={getPhotographerTwitterUrl(photographer)}
          target="_blank"
          rel="noreferrer"
          className="absolute left-2 bottom-2"
        >
          <div className="text-sm font-semibold flex space-x-2 items-center text-white">
            <img src={getPhotographerImage(photographer)} alt={photographer.name} className="w-8 h-8 rounded-full" /><span>{photographer.name}</span>
          </div>
        </a>
      </div>
    </div>
  )
}