import { Link, useLocation } from "react-router-dom"
import StripesSVG from "../images/stripes.svg"

const Header = () => {
  return (
    <header className="fixed top-0 left-0 right-0 py-2 max-w-screen-lg w-full mx-auto">
      <div className="flex items-center justify-between bg-surface px-4 rounded">
        <div className=" w-16 h-4 mr-4 overflow-hidden">
          <img src={StripesSVG} className=" w-full h-full object-cover" alt="" />
        </div>
        <div className="flex-1 h-px bg-gray-100 mr-4 opacity-20" />
        <div className="flex justify-center items-center space-x-4 shrink-0">
          <HeaderNavItem name="Home" url="/" />
          <HeaderNavItem name="Gallery" url="/gallery" />
          <HeaderNavItem name="Photographers" url="/photographers" />
        </div>
      </div>
    </header> 
  )
}

export default Header

const HeaderNavItem = ({
  name,
  url,
}: {
  name: string,
  url: string
}) => {
  const location = useLocation()
  const isCurrentLocation = location.pathname === url
  return (
    <Link className={`py-2 uppercase font-thin text-sm border-b-2 ${isCurrentLocation ? "border-primary" : "border-transparent"}`} to={url}>
      {name}
    </Link>
  )
}